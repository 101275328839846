import React,  {useState, Fragment, useEffect } from 'react'
import queryString from 'query-string';
import MuiAlert from '@mui/material/Alert';

import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button.js"
import Icon from "@mui/material/Icon"
import GridContainer from "components/material-dashboard-pro-react/components/Grid/GridContainer.js"
import GridItem from "components/material-dashboard-pro-react/components/Grid/GridItem.js"

import { initAxiosInterceptors } from "utils/axiosConfig"
import { useDialog } from 'context/DialogContext'
import { useLoading } from "context/LoadingContext"
import { format } from "date-fns"

import Axios from 'axios'
import BudgetTitle from 'Portal/Views/Budget/BudgetTitle'
import TemplateBlank from 'LandingPageMaterial/Layout/TemplateBlank'
import {navigate} from 'gatsby'


import Typography from "@mui/material/Typography"
import Backdrop from "@mui/material/Backdrop"
import { CircularProgress } from '@mui/material';
export default function ResultadoPago(props) {
    let params_url = queryString.parse(props.location.search)
    const dialog = useDialog()
    const loading = useLoading()
    const [policies, setPolicies] = useState()
    const [income, setIncome] = useState()
    const [reportId, setReportId] = useState()
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() =>{
        initAxiosInterceptors(dialog,loading)
        
    },[])

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }
    

   const getDataPaySuccess = async (orderId) => {

    const params = {
        p_order_number : parseInt(orderId)
    }
    
    
    await Axios.post("/dbo/treasury/get_data_pay", params).then(respuesta => {
       
        setPolicies(respuesta.data.p_cur_data)

       onSuccessPay()
    });

    
   }
   
   
   const onSuccessPay = async() => {

    
 
      const dataPay = {
        p_string_json_status_oper: JSON.stringify({
          company_operation_number: parseInt(params_url.order_id),
          status_operation: 'PagoOK',
          short_approval_code: params_url.reference_batpay,
          long_approval_code: params_url.reference_batpay
        }),
        p_string_list_json_data: JSON.stringify([{
          company_operation_number:parseInt(params_url.order_id),
          date_approval: format(new Date(params_url.fecha), 'dd/MM/yyyy'),
          another_approval_code:'S/N',
          payment_document_type: 'TAP',
          amount_value:parseFloat(params_url.amount),
          commission_amount:0
        }]),
      }


    setIsLoading(true)
    await Axios.post(`/dbo/treasury/update_merchant_operation`, dataPay).then(
        resp => {
          setIncome(resp.data.result.income_ratio_number)
          setReportId(resp.data.result.report_id) 
          setIsLoading(false)
        }
      ).catch( err =>{
        setIsLoading(false)
      }

      )
    }

    

    async function onDownloadPayroll() {
        try {

            let response
            let countPrint = 1
             policies.forEach(async(value)=>{        
                     
                let dataCuadroPoliza = { IDREPORTE: 5, IDEPOL: parseInt(value.IDEPOL), NUMCERT: parseInt(value.NUMCERT) }
                let params = { p_params: JSON.stringify(dataCuadroPoliza) }
                response =  await Axios.post('/dbo/general_policies/get_report', params)
                let reportId2 = response.data.p_url 
                let paramsRelIng = {
                  p_report_id: reportId ? reportId : value.INDFINAN === 'S'? 161 : 21,
                  p_json_parameters: JSON.stringify({p_numreling: income})
                }

                let {data} = await Axios.post('/reports/get',paramsRelIng);
                let blob = new Blob([data], { type: 'application/pdf' })
                let url = URL.createObjectURL(blob);
                window.open(`/reporte?reportRunId=${reportId2}`,"_blank");
                if (countPrint === 1){
                    window.open(`/reporte?urlReport=${btoa(url)}`,"_blank");
                    countPrint = countPrint + 1
                } 
                
            }) 

  
  
  
        } catch (err) {
            console.error(err)
      }        
    }
    

    function handleBack (e){
        e.preventDefault();
        navigate(`/pagos`, { replace: true })
    }



    useEffect(() => {
        params_url && params_url.success === 'True' && getDataPaySuccess(params_url.order_id) 
    }, [])

    return (
        
        <TemplateBlank>
                {isLoading &&
                    <Backdrop style={{ zIndex: "9999" }} open={isLoading}>
                    <CircularProgress color="primary" />
                    </Backdrop>
                }

                {params_url.success === 'True' && 


                    <Fragment>
                    <BudgetTitle title="Su póliza" />
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3} >
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} >
                            <GridItem xs={12} sm={12} md={12} className="sections30">
                                <Alert severity="success">{`¡Gracias por tu compra!`}</Alert>
                            </GridItem>
                            <GridContainer justify="center" className="sections30">
                                <Button color="primary" onClick={onDownloadPayroll}>
                                    <Icon>cloud_download</Icon> Descarga tu cuadro póliza
                                </Button>
                            </GridContainer>
                            
                        </GridItem>
                    </GridContainer>
                    </Fragment>


                 } 

                {params_url.success === 'False' &&

                <Fragment>
                   <BudgetTitle title={"Realice su pago"} />
                    <GridContainer justify="center">
                        <Typography variant="caption"> O puede dirigirse a nuestras oficinas para formalizar el pago en efectivo.</Typography>
                    </GridContainer>
                    <GridContainer justify="center">
                        <Typography variant="h5">Su pago no fue procesado</Typography>
                    </GridContainer>

                    
                </Fragment>


                }

            <GridContainer justify="center">
                <Button color="secondary" onClick={handleBack}>
                    <Icon>fast_rewind</Icon> Regresar
                </Button>            
            </GridContainer>
           
        </TemplateBlank>
       
        
    )
}
